.tools-title {
  h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }
  p {
    margin: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.404);
  }
  padding: 40px;
}

.tools-items {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  padding: 0 40px;
  margin-bottom: 80px;
}

@media (min-width: 600px) {
  .tools-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .tools-items {
    grid-template-columns: repeat(4, 1fr);
  }
}

.tools-play {
  &:hover {
    transform: translateX(-8px) translateY(-8px);
    box-shadow: 8px 8px rgba(0, 0, 0, 0.808);
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  h4 {
    margin: 0;
    margin-bottom: 10px;
  }
  p {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.438);
    margin: 0;
  }
  background-color: white;
  width: 100%;
  height: fit-content;
  color: inherit;
  text-decoration: none;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.123);
  padding: 10px;
  padding-bottom: 25px;
  word-break: keep-all;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
