@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

:root {
  --highlight-color: rgba(132, 65, 255, 0.973);
}

* {
  &::selection {
    background-color: rgba(132, 65, 255, 0.13);
  }
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
}

a {
  color: inherit;
}

body {
  margin: 0;
}

header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.global-header-body {
  h1 {
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  color: rgba(0, 0, 0, 0.719);
  padding: 20px;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

.global-header-links {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 15px;
}

main {
  max-width: 1200px;
  margin: 0 auto;
}

.not-support {
  h1 {
    font-size: 25px;
    margin: 0;
  }
  p {
    font-size: 15px;
    margin: 0;
  }
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

footer {
  background-color: rgba(0, 0, 0, 0.048);
}

.global-footer-body {
  a {
    color: inherit;
    text-decoration: none;
  }
  h1 {
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
  color: rgba(0, 0, 0, 0.719);
  padding: 60px 40px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: end;
}

.copyright {
  margin: 0;
  font-size: 12px;
}
