.play {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.play-title {
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  margin: 20px 0;
  color: rgba(0, 0, 0, 0.705);
}

.odd-console-body {
  overflow: scroll;
  height: 100px;
  transition: height 0.3s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.214);
  padding: 5px;
  border-radius: 10px;
  margin: 10px 0;
}

.odd-console-activated {
  height: 300px;
}
