.about {
  h1 {
    font-family: "Nunito Sans", sans-serif;
    font-size: 40px;
    font-weight: 600;
    margin: 0;
  }
  h2 {
    font-family: "Nunito Sans", sans-serif;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    font-size: 15px;
  }
  color: rgba(0, 0, 0, 0.678);
  font-family: "Nunito Sans", sans-serif;
  padding: 40px;
}
